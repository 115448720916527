import util from '@bmp-vue/core/api/util'

const controller = 'ContaCorrente'
export default Object.assign({}, util.GetCrud(controller, ['getall', 'put', 'post']), {
    GetAllOcorrencias: function (dto) {
        return util.Axios.GetAll(controller + '/Ocorrencias', dto)
    },
    Sincronizar: function (dto) {
        return util.Axios.Post(controller + '/Sincronizar', dto)
    }
})

