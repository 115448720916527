import util from "@bmp-vue/core/api/util";

const controller = "Pessoa";
export default Object.assign({}, util.GetCrud(controller, null), {
  AtualizarSituacaoEcSso: function (dto) {
    var pessoa = {
      Nome: dto.nome,
      Situacao: 1,
      TipoPessoa: "",
      DocumentoFederal: dto.documentoFederal,
      Email: dto.email,
      CadastroUsuarioSso: dto.cadastroUsuarioSso
    };

    return util.Axios.Post(`${controller}/AtualizaSituacaoEcSso`, pessoa);
  },
  
  GetPessoaPorDocumentoFederal: function(documentoFederal) {
    const url = `GetPessoaPorDocumentoFederal?documentoFederal=${documentoFederal}`;
    return util.Axios.GetAll(`${controller}/${url}`);
  },

  AdicionarContaCorrenteParaPessoa: function(codigo, contaPagto) {
    var dto = {
      ContaPagto: contaPagto
    };
    return util.Axios.Post(`${controller}/${codigo}/contaCorrente`, dto);
  },

  ReenvioDeCredenciaisSsoPorEmail: function (codigoParceiro) {
    const url = `CadastrarUsuarioDoEcNoSso?codigo=${codigoParceiro}`;
    return util.Axios.Post(`${controller}/${url}`);
  }
});
